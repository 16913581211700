import axios from 'axios'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  align-items: center;
  background: lightblue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
`

const Response = styled.pre`
  padding-top: 30px;
`

export default class App extends React.Component {
  constructor () {
    super()
    this.state = {}
  }

  componentDidMount () {
    axios
      .get('/detailsa')
      .then(resp => this.setState({ response: resp.data }))
      .catch(err => console.error(err))
  }

  render () {
    return (
      <Wrapper>
        <div>hi</div>
        <Response>{this.state.response}</Response>
      </Wrapper>
    )
  }
}
